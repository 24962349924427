import Link from 'next/link'
import React from 'react'
import ctaStyles from '../styles.module.scss'
import type { IconPosition, Theme, Variant } from '@features/_ui/cta/_types'
import RenderIf from '@root/utils/render-if'
import { hasProp } from '@utils/has-prop'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'

export interface Props {
  external: boolean
  icon?: React.ReactNode
  iconPosition?: IconPosition
  label: string
  openSameTab?: boolean
  path: string
  theme?: Theme
  variant?: Variant
  bold?: boolean
  hideIconMobile?: boolean
  className?: string
  disclaimer?: string
  channelTheme?: ChannelThemeInterface
  fullSize?: boolean
}

export default function Hyperlink({
  bold = false,
  className = '',
  external,
  hideIconMobile = false,
  icon,
  iconPosition = 'left',
  label,
  openSameTab = false,
  path,
  theme,
  disclaimer,
  variant = 'hyperlink',
  channelTheme,
  fullSize = false,
}: Props): JSX.Element {
  // Styles
  const hyperLinkClass = [ctaStyles.btn, className, ctaStyles[variant]]
  const hasIcon = typeof icon !== 'undefined'

  if (bold) {
    hyperLinkClass.push(ctaStyles.bold)
  }

  if (hasIcon) {
    hyperLinkClass.push(ctaStyles.icon)
  }

  if (hideIconMobile) {
    hyperLinkClass.push(ctaStyles.hideIconMobile)
  }

  // Theme to use. Only available when the variant is Hyperlink because only works on the color font
  if (theme !== undefined) {
    hyperLinkClass.push(ctaStyles[theme])
  }

  if (fullSize) {
    hyperLinkClass.push(ctaStyles.fullSize)
  }

  const styleClassesToUse = hyperLinkClass.join(' ')

  // Hyper link content.
  const hyperlinkContentClasses = [
    ctaStyles.hyperlinkContent,
    iconPosition === 'left' ? ctaStyles.left : ctaStyles.right,
  ].join(' ')
  const renderContentLink =
    variant !== 'onlyIcon' ? (
      <p className={hyperlinkContentClasses}>
        <RenderIf isTrue={hasIcon}>{icon}</RenderIf>
        <span>{label}</span>
      </p>
    ) : (
      <RenderIf isTrue={hasIcon}>{icon}</RenderIf>
    )

  // Building elements
  let hyperlink = null

  // If the link is external, we don't need to use Next JS router
  if (external) {
    let properties = {}

    if (!openSameTab) {
      properties = { target: '_blank', rel: 'noopener noreferrer' }
    }

    hyperlink = (
      <a href={path} {...properties} className={styleClassesToUse}>
        {renderContentLink}
      </a>
    )
  } else {
    hyperlink = (
      <Link href={path}>
        <a className={styleClassesToUse}>{renderContentLink}</a>
      </Link>
    )
  }

  const cssVariables: Record<string, unknown> = {
    '--btnColour': channelTheme?.buttonColour ?? 'var(--vidzingEarth)',
  }

  return (
    <div className={ctaStyles.hyperlinkWrapper} style={cssVariables}>
      {hyperlink}
      <RenderIf isTrue={hasProp(disclaimer)}>
        <p>{disclaimer}</p>
      </RenderIf>
    </div>
  )
}
